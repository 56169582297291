/* eslint-disable */
function MetaText(props) {
  const type = props.type;
  switch (type) {
    case "Overview":
      return (
        <>
          {" "}
          <h2 id="meta_heading">Overview</h2>{" "}
          <div>
            {" "}
            Our metadata comprehensively describe every dataset in the API.
            Metadata can help you better understand the properties and
            characteristics of the variables within a dataset and inform you
            whether the data will be useful for specific purposes prior to
            access. For our datasets, we provide information about each
            variable’s:
          </div>
          <br />
          <ul>
            <li>
              <b>Substantive values</b> – values that are present, valid, and
              consistent with the variable’s intended definition and use.
            </li>
            <li>
              <b>Missing values</b> – values that indicate the data are not
              available, were not collected, or were not provided, or no data
              value is stored.
            </li>
            <li>
              <b>Valueless entries</b> – entries that resemble valid data but
              cannot be interpreted because they do not conform to a reference
              or standard value, or cannot be translated into a meaningful
              value.
            </li>
            <li>
              <b>Descriptive statistics</b> – measures that characterize the
              distribution of values, including counts, minimums, maximums,
              quartiles, and averages.
            </li>
          </ul>{" "}
          <p>
            Use the left navigation bar to begin exploring our metadata
            elements.
          </p>{" "}
        </>
      );

    case "Metadata":
      return (
        <>
          <h2 id="meta_heading">Substantive Values</h2>
          <div>
            {" "}
            The metadata elements below describe the meaningful values within a
            variable. The metadata offer useful, descriptive properties of data,
            describing the value domain, and help to differentiate and identify
            the values of a dataset that are fit for purpose and meaningful from
            those values that appear to be valid but can’t be interpreted (see
            Valueless entries).
          </div>
          <section className="btc-accordion" id="meta_accordian">
            <div className="row">
              <div className="col-md-12">
                <p>
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="show_all_button"
                  >
                    Show all
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="hide_all_button"
                  >
                    Hide all
                  </button>
                </p>

                <div id="accordion1">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapseone"
                        aria-expanded="false"
                        aria-controls="collapseone"
                      >
                        <h5 class="py-2">
                          variable_id
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapseone"
                      class="collapse"
                      aria-labelledby="headingone"
                      data-parent="#accordion1"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Unique identifier for the metadata describing each
                          variable (agency name abbreviation + endpoint name +
                          variable sequence number).{" "}
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          osha_inspection_17 would be the 17th variable of
                          OSHA's inspection dataset.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion2">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwo"
                        aria-expanded="false"
                        aria-controls="collapsetwo"
                      >
                        <h5 class="py-2">
                          short_name
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwo"
                      class="collapse"
                      aria-labelledby="headingtwo"
                      data-parent="#accordion2"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Abbreviated name for a variable, and generally the
                          name of the variable as it will appear within the
                          data.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          naic_cd is a short name for North American Industry
                          Classification System codes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion3">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsethree"
                        aria-expanded="false"
                        aria-controls="collapsethree"
                      >
                        <h5 class="py-2">
                          full_name
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsethree"
                      class="collapse"
                      aria-labelledby="headingthree"
                      data-parent="#accordion3"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Plain language name for a variable.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          North American Industry Classification System codes is
                          a full name for naic_cd.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion4">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsefour"
                        aria-expanded="false"
                        aria-controls="collapsefour"
                      >
                        <h5 class="py-2">
                          variable_description
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsefour"
                      class="collapse"
                      aria-labelledby="headingfour"
                      data-parent="#accordion4"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Description of the content in a variable.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          The amount of back wages agreed to pay under the Fair
                          Labor Standards Act.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion5">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsefive"
                        aria-expanded="false"
                        aria-controls="collapsefive"
                      >
                        <h5 class="py-2">
                          variable_category
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsefive"
                      class="collapse"
                      aria-labelledby="headingfive"
                      data-parent="#accordion5"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Intended purpose and use of the variable.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          Classification is the variable category for North
                          American Industry Classification System codes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion6">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsesix"
                        aria-expanded="false"
                        aria-controls="collapsesix"
                      >
                        <h5 class="py-2">
                          variable_type
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsesix"
                      class="collapse"
                      aria-labelledby="headingsix"
                      data-parent="#accordion6"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          An indicator for how data are obtained. Variable types
                          include:
                          <ul>
                            <li>
                              acquired (through an instrument, questionnaire,
                              form, etc.);
                            </li>
                            <li>
                              calculated (through some mathematical formula);
                            </li>
                            <li> derived (through if-then-else steps); and</li>{" "}
                            <li>
                              inherited (through ingestion of a data set from
                              another organizaton).
                            </li>
                          </ul>
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          Inspection type is an aquired variable from an
                          inspection.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion7">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapseseven"
                        aria-expanded="false"
                        aria-controls="collapseseven"
                      >
                        <h5 class="py-2">
                          intended_datatype
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapseseven"
                      class="collapse"
                      aria-labelledby="headingseven"
                      data-parent="#accordion7"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          This is the format for the data content that the data
                          creators intended for people to use. It supports more
                          optimal interpretation and usage of data. Intended
                          datatypes include:
                          <ul>
                            <li>
                              text (string of characters with no inherent
                              structure);
                            </li>
                            <li>
                              categorical (finite, pre-defined list of values or
                              code/value pairs);
                            </li>
                            <li>numeric (values representing numbers);</li>
                            <li>currency (values for currencies);</li>
                            <li>
                              boolean (binary categories such as yes/no or
                              true/false);
                            </li>
                            <li>date;</li>
                            <li>time; and</li>
                            <li>
                              lat/long (latitude and longitude, for specific
                              points on the earth).
                            </li>
                          </ul>
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          The intended datatype for violation type is
                          categorical: the values are stored as text, but the
                          text represents categories describing the severity or
                          prevalence of violations
                          (S;Serious|W;Willful|R;Repeat|O;Other). The categories
                          can be found in the allowed_value_list and their
                          frequencies can be found in the
                          allowed_category_counts in the metadata.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion8">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapseeight"
                        aria-expanded="false"
                        aria-controls="collapseeight"
                      >
                        <h5 class="py-2">
                          application_datatype
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapseeight"
                      class="collapse"
                      aria-labelledby="headingeight"
                      data-parent="#accordion8"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          This is the format of the data in the source database
                          for the API (number, varchar, boolean, date, and
                          timestamp_ntz). It supports more optimal
                          interpretation and usage of data by describing how the
                          data were formatted before being translated into JSON,
                          XML, or CSV.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          Accident narrative is a free text entry description of
                          a workplace accident and is stored in the API database
                          as varchar.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion9">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsenine"
                        aria-expanded="false"
                        aria-controls="collapsenine"
                      >
                        <h5 class="py-2">
                          linked_tables
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsenine"
                      class="collapse"
                      aria-labelledby="headingnine"
                      data-parent="#accordion9"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          This indicates that the variable functions as a
                          primary or foreign key, linking aspects of these data
                          with data in other tables. The values will indicate
                          which other data, and which variables within those
                          other data are linked through these relations.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          case_id links to case_id in table 1 and
                          case_identifier in table 2.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion10">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapseten"
                        aria-expanded="false"
                        aria-controls="collapseten"
                      >
                        <h5 class="py-2">
                          universe
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapseten"
                      class="collapse"
                      aria-labelledby="headingten"
                      data-parent="#accordion10"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Description of the outcomes, events, processes, or
                          units to which a variable applies.{" "}
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          Wages is the universe for the amount of back wages
                          agreed to pay under the Fair Labor Standards Act.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion11">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapseeleven"
                        aria-expanded="false"
                        aria-controls="collapseeleven"
                      >
                        <h5 class="py-2">
                          value_domain_id
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapseeleven"
                      class="collapse"
                      aria-labelledby="headingeleven"
                      data-parent="#accordion11"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Unique identifier for each value domain ( "vd_" +
                          sequence number).
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          vd_5 would be the value domain 5.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion12">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwelve"
                        aria-expanded="false"
                        aria-controls="collapsetwelve"
                      >
                        <h5 class="py-2">
                          value_domain_name
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwelve"
                      class="collapse"
                      aria-labelledby="headingtwelve"
                      data-parent="#accordion12"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Plain language label for the value domain.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          Industry (NAICS) is a value domain name for industries
                          defined by the North Americain Industry Classification
                          System.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion13">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsethirteen"
                        aria-expanded="false"
                        aria-controls="collapsethirteen"
                      >
                        <h5 class="py-2">
                          value_domain_structure
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsethirteen"
                      class="collapse"
                      aria-labelledby="headingthirteen"
                      data-parent="#accordion13"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          The structure for a value domain can be:{" "}
                          <ul>
                            <li>
                              List (a set of categories and related codes);
                            </li>
                            <li>
                              Rule (layout of the components that make up a
                              value);
                            </li>
                            <li>Range (interval of numbers); and</li>
                            <li>
                              Reference (URL to an external web site where some
                              code list is maintained).
                            </li>
                          </ul>
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          Rule is the structure for citation issuance date
                          because dates have a defined format.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion14">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsefourteen"
                        aria-expanded="false"
                        aria-controls="collapsefourteen"
                      >
                        <h5 class="py-2">
                          value_domain_description
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsefourteen"
                      class="collapse"
                      aria-labelledby="headingfourteen"
                      data-parent="#accordion14"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Description of the content in a value domain.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          yyyy-mm-dd (4-digit year, 2-digit month, and 2-digit
                          day) describes the rule for citation issuance date.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion15">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsefifteen"
                        aria-expanded="false"
                        aria-controls="collapsefifteen"
                      >
                        <h5 class="py-2">
                          allowed_value_list
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsefifteen"
                      class="collapse"
                      aria-labelledby="headingfifteen"
                      data-parent="#accordion15"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          List of allowed values for boolean or categorical
                          variables. The frequencies for the categories can be
                          found in the allowed_category_counts in the metadata.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          Facility, Surface, or Underground are the allowed
                          values for mine type code.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion16">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsesixteen"
                        aria-expanded="false"
                        aria-controls="collapsesixteen"
                      >
                        <h5 class="py-2">
                          length
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsesixteen"
                      class="collapse"
                      aria-labelledby="headingsixteen"
                      data-parent="#accordion16"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          For boolean and categorical variables, length
                          represents the number of allowed values in a list. For
                          text variables, length represents the maximum length
                          of a string found in the data.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          3 is length of the allowed_value_list for mine type
                          code (Facility, Surface, or Underground).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion17">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapseseventeen"
                        aria-expanded="false"
                        aria-controls="collapseseventeen"
                      >
                        <h5 class="py-2">
                          allowed_variable_range
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapseseventeen"
                      class="collapse"
                      aria-labelledby="headingseventeen"
                      data-parent="#accordion17"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          The minimum and maximum values allowed for numeric and
                          currency ranges, and dates.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          Inspection open date can be any date between 1970 and
                          present. Any date found outside of this range would be
                          a valueless entry (see valueless_list in the
                          metadata).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion18">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapseeighteen"
                        aria-expanded="false"
                        aria-controls="collapseeighteen"
                      >
                        <h5 class="py-2">
                          precision
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapseeighteen"
                      class="collapse"
                      aria-labelledby="headingeighteen"
                      data-parent="#accordion18"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Precision applies to number of decimal places or
                          degree of rounding in numeric or currency data, to the
                          level from which codes are taken in a hierarchical
                          classification system like NAICS, or to the level of
                          detail in date or time data.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          <ol>
                            <li>
                              Amount of back wages agreed to pay under the Fair
                              Labor Standards Act is a currency amount with a
                              precision of 2 (i.e. 2 decimal places).
                            </li>
                            <li>
                              A variable with a date format (i.e. yyyy-mm-dd)
                              would have a precision of day.
                            </li>
                          </ol>
                          <br />
                          Similar to intended_data_type, this should help users
                          make informed decisions about how to optimally format
                          and leverage the data.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion19">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsenineteen"
                        aria-expanded="false"
                        aria-controls="collapsenineteen"
                      >
                        <h5 class="py-2">
                          unit_of_measure
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsenineteen"
                      class="collapse"
                      aria-labelledby="headingnineteen"
                      data-parent="#accordion19"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Units used to interpret values.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          Dollars is the unit of measure for the amount of back
                          wages agreed to pay under the Fair Labor Standards
                          Act.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );
    case "missingValues":
      return (
        <>
          <h2 id="meta_heading">Missing values</h2>
          <p>
            Missing data can be absent, or can be values represented in various
            ways within a dataset that by their content or format indicate the
            absence of useful information. The metadata elements below are used
            to describe the specific instances of missing values for each
            variable. This information can help you assess the potential utility
            of a specific variable or data set, and to identify patterns of
            missingness within the data to assess whether the data are suitable
            for your needs.
          </p>
          <section className="btc-accordion" id="meta_accordian">
            <div className="row">
              <div className="col-md-12">
                <p>
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="show_all_button"
                  >
                    Show all
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="hide_all_button"
                  >
                    Hide all
                  </button>
                </p>

                <div id="accordion20">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwenty"
                        aria-expanded="false"
                        aria-controls="collapsetwenty"
                      >
                        <h5 class="py-2">
                          missing_value_list
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwenty"
                      class="collapse"
                      aria-labelledby="headingtwenty"
                      data-parent="#accordion20"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          List of values present in the data that indicate there
                          are not valid values. This could be because the value
                          was not collected, was suppressed from public data,
                          was out of scope, etc.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          "NA", "N/A", "missing", etc. could indicate missing
                          values.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion21">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwenty-one"
                        aria-expanded="false"
                        aria-controls="collapsetwenty-one"
                      >
                        <h5 class="py-2">
                          missing_list_length
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwenty-one"
                      class="collapse"
                      aria-labelledby="headingtwenty-one"
                      data-parent="#accordion21"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Missing list length represents the number of values
                          present in the data that indicate there are not valid
                          values.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          3 would be the missing list length for a variable if
                          missing is indicated by "NA", "N/A", and "missing".
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );

    case "valuelessEntries":
      return (
        <>
          <h2 id="meta_heading">Valueless entries</h2>
          <p>
            Valueless entries are data that resemble valid data but cannot be
            interpreted because they do not conform to a reference or standard
            value, or cannot be translated into a meaningful value. The metadata
            element below describes how to understand and access metadata
            describing valueless entries.
          </p>
          <section className="btc-accordion" id="meta_accordian">
            <div className="row">
              <div className="col-md-12">
                <p>
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="show_all_button"
                  >
                    Show all
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="hide_all_button"
                  >
                    Hide all
                  </button>
                </p>
                <div id="accordion25">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwenty-five"
                        aria-expanded="false"
                        aria-controls="collapsetwenty-five"
                      >
                        <h5 class="py-2">
                          valueless_list
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwenty-five"
                      class="collapse"
                      aria-labelledby="headingtwenty-five"
                      data-parent="#accordion25"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Link (API Call) to a table of valueless entries for
                          each variable in the dataset. Valueless entries for
                          each dataset can be accessed in the API at
                          https://apiprod.dol.gov/v4/get/odg/valueless/csv?limit=10000&X-API-KEY=YOUR_API_KEY.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          An industry variable with a value domain of industry
                          (NAICS) would have a set of allowed values that are
                          defined by the{" "}
                          <a href="https://www.census.gov/naics/">
                            North American Industry Classification System
                            standard
                          </a>
                          . Any value resembling an industry that doesn't
                          conform to that standard would be considered a
                          valueless entry and be captured in the valuless_list
                          table.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );

    case "descriptiveStatistics":
      return (
        <>
          <h2 id="meta_heading">Descriptive statistics</h2>
          <p>
            Descriptive statistics can help you to understand the range of
            possible values, the variable distribution of numeric and currency
            variables, and the category counts of allowed and missing values.
            This information is helpful for identifying outliers and specifying
            the range of values you want to query as a subset of data from the
            API using our filtering capability. Explore the metadata elements
            below to learn more.
          </p>
          <section className="btc-accordion" id="meta_accordian">
            <div className="row">
              <div className="col-md-12">
                <p>
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="show_all_button"
                  >
                    Show all
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="hide_all_button"
                  >
                    Hide all
                  </button>
                </p>

                <div id="accordion22">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwenty-two"
                        aria-expanded="false"
                        aria-controls="collapsetwenty-two"
                      >
                        <h5 class="py-2">
                          number_allowed_values
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwenty-two"
                      class="collapse"
                      aria-labelledby="headingtwenty-two"
                      data-parent="#accordion22"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Number of records with substantive data for a variable
                          (i.e. meaningful data that are valid and consistent
                          with the variable's intended definition).
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          total record count (1000) ={" "}
                          <b>number of allowed values (900)</b> - number of
                          missing values (90) - number of valueless entries (10)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion23">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwenty-three"
                        aria-expanded="false"
                        aria-controls="collapsetwenty-three"
                      >
                        <h5 class="py-2">
                          number_missing_values
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwenty-three"
                      class="collapse"
                      aria-labelledby="headingtwenty-three"
                      data-parent="#accordion23"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Number of records with missing data for a variable.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          total record count (1000) = number of allowed values
                          (900) - <b>number of missing values (90)</b> - number
                          of valueless entries (10)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion24">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwenty-four"
                        aria-expanded="false"
                        aria-controls="collapsetwenty-four"
                      >
                        <h5 class="py-2">
                          number_valueless
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwenty-four"
                      class="collapse"
                      aria-labelledby="headingtwenty-four"
                      data-parent="#accordion24"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Number of records with representations that resemble
                          valid data but cannot be interpreted because they do
                          not conform to a reference or standard value, or
                          cannot be translated into a meaningful value.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          total record count (1000) = number of allowed values
                          (900) - number of missing values (90) -{" "}
                          <b>number of valueless entries (10)</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion26">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwenty-six"
                        aria-expanded="false"
                        aria-controls="collapsetwenty-six"
                      >
                        <h5 class="py-2">
                          minimum
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwenty-six"
                      class="collapse"
                      aria-labelledby="headingtwenty-six"
                      data-parent="#accordion26"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          The minimum value for numeric or date data, excluding
                          missing entries.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          A minimum of 0 for a numeric variable would indicate
                          that the smallest number found in the data is 0. The
                          minimum allowed value of a variable can be found in
                          the allowed_variable_range of the metadata.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion27">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwenty-seven"
                        aria-expanded="false"
                        aria-controls="collapsetwenty-seven"
                      >
                        <h5 class="py-2">
                          first_quartile
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwenty-seven"
                      class="collapse"
                      aria-labelledby="headingtwenty-seven"
                      data-parent="#accordion27"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          The value at which 25% of the values are below that
                          value, excluding missing entries.{" "}
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          A first_quartile of 0 for a numeric variable would
                          indicate that at least 25% of the variable values are
                          0.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion28">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwenty-eight"
                        aria-expanded="false"
                        aria-controls="collapsetwenty-eight"
                      >
                        <h5 class="py-2">
                          mean
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwenty-eight"
                      class="collapse"
                      aria-labelledby="headingtwenty-eight"
                      data-parent="#accordion28"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          Value for the mean for numeric data, excluding missing
                          entries.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          A mean of 10 for a numeric variable would indicate
                          that the average value is 10.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion29">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsetwenty-nine"
                        aria-expanded="false"
                        aria-controls="collapsetwenty-nine"
                      >
                        <h5 class="py-2">
                          median
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsetwenty-nine"
                      class="collapse"
                      aria-labelledby="headingtwenty-nine"
                      data-parent="#accordion29"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          The value that has approximately or exactly the same
                          number of lesser values below it and greater values
                          above it, excluding missing entries.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          A median of 0 for a numeric variable would indicate
                          that at least 50% of the variable values are 0.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion30">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsethirty"
                        aria-expanded="false"
                        aria-controls="collapsethirty"
                      >
                        <h5 class="py-2">
                          third_quartile
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsethirty"
                      class="collapse"
                      aria-labelledby="headingthirty"
                      data-parent="#accordion30"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          The value at which 75% of the values are below that
                          value, excluding missing entries.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          A third_quartile of 0 for a numeric variable would
                          indicate that at least 75% of the variable values are
                          0.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion31">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsethirty-one"
                        aria-expanded="false"
                        aria-controls="collapsethirty-one"
                      >
                        <h5 class="py-2">
                          maximum
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsethirty-one"
                      class="collapse"
                      aria-labelledby="headingthirty-one"
                      data-parent="#accordion31"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          The maximum value for numeric or date data, excluding
                          missing entries.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          A maximum of 1000 for a numeric variable would
                          indicate that the largest number found in the data is
                          1000. The maximum allowed value of a variable can be
                          found in the allowed_variable_range of the metadata.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion32">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsethirty-two"
                        aria-expanded="false"
                        aria-controls="collapsethirty-two"
                      >
                        <h5 class="py-2">
                          allowed_category_counts
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsethirty-two"
                      class="collapse"
                      aria-labelledby="headingthirty-two"
                      data-parent="#accordion32"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          For categorical data, list of categorical values and
                          counts for each of the substantive categories.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          R;14681|W;3363|RW;1699|N/A;320722 are the
                          allowed_category_counts for the Fair Labor Standards
                          Act Repeat and Willful violators where R=Repeat;
                          W=Willful; RW=Repeat and Willful; N/A=Not Applicable.
                          This information indicates the valid values and
                          frequencies within those values, describing the
                          distribution of values among categorical variables.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="accordion33">
                  <div class="card">
                    <div class="card-header">
                      <button
                        class="accordion-icon"
                        data-toggle="collapse"
                        data-target="#collapsethirty-three"
                        aria-expanded="false"
                        aria-controls="collapsethirty-three"
                      >
                        <h5 class="py-2">
                          missing_category_counts
                          <span class="float-right">
                            <i class="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </button>
                    </div>

                    <div
                      id="collapsethirty-three"
                      class="collapse"
                      aria-labelledby="headingthirty-three"
                      data-parent="#accordion33"
                    >
                      <div class="card-body">
                        <p>
                          <b>Description:</b>
                          <br />
                          List of missing value indicators and counts for
                          indicators of missing values, if applicable.
                        </p>
                        <p>
                          <b>
                            Example:
                            <br />
                          </b>
                          N/A;3|NA;1|N/a;1|Not Available;1|Not available;1 are
                          the missing_category_counts for employer street
                          address. This describes the various ways used to
                          indicate missing data, as well as understanding how
                          missing values are distributed among different
                          categories.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );

    default:
  }
}

export default MetaText;
