/* eslint-disable */
import { React } from "react";
import $ from "jquery";

function TutorialText(props) {
  const { type } = props;
  $("#transcript_toggle_button").click(function (ev) {
    ev.preventDefault();

    if ($(this).text() === "Show transcript") {
      $(this).text("Hide transcript");
      $("#transcript_container").show();
    } else {
      $(this).text("Show transcript");
      $("#transcript_container").hide();
    }
  });
  switch (type) {
    case "gettingStarted":
      return (
        <>
          <h2 id="meta_heading">Getting Started</h2>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about Finding data in the Open Data Catalog"
              src="https://www.youtube.com/embed/ZAsTTRCJh44"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>
          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              Transcript and closed captioning on videos coming soon! If you
              have feedback on the videos, please{" "}
              <a href="/contact-us">Contact Us</a>.
            </p>
          </div>
        </>
      );

    case "findData":
      return (
        <>
          <h2 id="meta_heading">Finding Data in the Data Catalog</h2>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about Finding data in the Open Data Catalog"
              src="https://www.youtube.com/embed/ZAsTTRCJh44"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>
          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              Transcript and closed captioning on videos coming soon! If you
              have feedback on the videos, please{" "}
              <a href="/contact-us">Contact Us</a>.
            </p>
          </div>
        </>
      );

    case "navigatingDataset":
      return (
        <>
          <h2 id="meta_heading">Navigating the Dataset Pages</h2>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about Finding data in the Open Data Catalog"
              src="https://www.youtube.com/embed/OQFd0sce3_8"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>
          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              Transcript and closed captioning on videos coming soon! If you
              have feedback on the videos, please{" "}
              <a href="/contact-us">Contact Us</a>.
            </p>
          </div>
        </>
      );

    case "queryBuilder":
      return (
        <>
          <h2 id="meta_heading">API Query Builder</h2>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about Finding data in the Open Data Catalog"
              src="https://www.youtube.com/embed/SE9uCl8eJ9c"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>
          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              Transcript and closed captioning on videos coming soon! If you
              have feedback on the videos, please{" "}
              <a href="/contact-us">Contact Us</a>.
            </p>
          </div>
        </>
      );

    case "apiExamples":
      return (
        <>
          <h2 id="meta_heading">API Examples</h2>
        </>
      );

    case "FLSA":
      return (
        <>
          <h2 id="meta_heading">API Example: FLSA Backwages by Industry</h2>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about Finding data in the Open Data Catalog"
              src="https://www.youtube.com/embed/bXlKI6Tq5jA"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>
          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              Transcript and closed captioning on videos coming soon! If you
              have feedback on the videos, please{" "}
              <a href="/contact-us">Contact Us</a>.
            </p>
          </div>
        </>
      );

    default:
  }
}

export default TutorialText;
