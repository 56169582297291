/* eslint-disable */
import { CodeBlock, dracula } from "react-code-blocks";
function CodeBlocks(props) {
  const language = props.language;
  const snippetContentPython = props.snippetContentPython;
  const snippetContentR = props.snippetContentR;
  const snippetContent = props.snippetContent;
  const configCodeBlock = props.configCodeBlock;

  const check = {
    Python: `      
            import requests trrrrrrrryyyyyyyyyyyy
            import pandas as pd
            
            # metadata endpoint URL
            url = 'https://apiprod.dol.gov/v4/get/<agency>/<api_url>/json/metadata'
            
            # api key for authentication
            params = {'X-API-KEY':'wtEX1ass8UuPBZyJUOq1GoXV7mPMR8jvb6Wlyxmfjd0'}
            
            # request metadata
            metadata_request = requests.get(url, params=params, verify=False)
            metadata_json = metadata_request.json()
            
            # converts json output to tabular dataframe
            df = pd.DataFrame(metadata_json['metadata'])`,
    R: `     library(httr)
            library(jsonlite)
            
            # set URL
            url = "https://apiprod.dol.gov/v4/get/<agency>/<api_url>/json/metadata"
            
            # set parameters
            params = list('X-API-KEY'='wtEX1ass8UuPBZyJUOq1GoXV7mPMR8jvb6Wlyxmfjd0')
            
            # request metadata
            metadata_request = GET(url,query=params)
            
            # convert from JSON
            metadata_json = fromJSON(content(metadata_request,"text"))
            
            # set as dataframe
            df = as.data.frame(metadata_json[1])`,
    Julia: ` using HTTP
            using JSON
            
            # metadata endpoint URL
            url = "https://apiprod.dol.gov/v4/get/<agency>/<api_url>/json/metadata"
            
            # api key for authentication
            params = Dict("X-API-KEY" => "wtEX1ass8UuPBZyJUOq1GoXV7mPMR8jvb6Wlyxmfjd0")
            
            # request metadata
            metadata_request = HTTP.get(url, query=params, require_ssl_verification=false)
            JSON.print(JSON.parse(String(metadata_request.body)), 4)`,
    getDataPython: `# Last updated on 11/06/2023 using Python 3.9.

# Import libraries
import requests, pandas as pd

# Basic request
url = "https://apiprod.dol.gov/v4/get/<agency>/<api_url>/json?X-API-KEY=<api_key>"         
request = requests.get(url, verify=False)
data = request.json()['data']
df = pd.DataFrame(data)

# Customized request: all parameters are optional except for your api key.
url = "https://apiprod.dol.gov/v4/get/<agency>/<api_url>/json"
params = {"X-API-KEY":"<api_key>",
    "limit":"<10>",
    "offset":"<0>",
    "fields":"<field1,field2,field3>",
    "sort":"<asc>",
    "sort_by":"<field1>",
    "filter_object":'{"field": "<YOUR_FIELD>", "operator": "<YOUR_OPERATOR>", "value": "<YOUR_VALUE>"}'
}
          
request = requests.get(url, params=params, verify=False)
data = request.json()['data']

df = pd.DataFrame(data)`,
    getDataR: `# Last updated on 11/06/2023 using R 4.1.3.

# Import libraries
library(httr)
library(jsonlite)

# Basic request
url <- "https://apiprod.dol.gov/v4/get/<agency>/<api_url>/json?X-API-KEY=<api_key>"
request <- GET(url)
data <- as.data.frame(fromJSON(content(request,"text")))

# Customized request: all parameters are optional except for your api key.
url <- "https://apiprod.dol.gov/v4/get/<agency>/<api_url>/json"
params <- list(
    "X-API-KEY"="<api_key>",
    "limit" = "<10>",
    "offset" = "<0>",
    "fields" = "<field1,field2,field3>",
    "sort" = "<asc>",
    "sort_by" = "<field1>",
    "filter_object" = gsub(
        "(\\\\])|(\\\\[)",
        "",
        toJSON(
            list(
                'field'='<YOUR_FIELD>',
                'operator'='<YOUR_OPERATOR>',
                'value'='<YOUR_VALUE>'
            )
        )
    )
)

request <- GET(url,query=params)
data <- as.data.frame(fromJSON(content(request,"text")))`,
    getMetaDataPython: `# Last updated on 11/06/2023 using Python 3.9.

# Import libraries
import requests, pandas as pd

# Get metadata
url = "https://apiprod.dol.gov/v4/get/<agency>/<api_url>/json/metadata"
params = {"X-API-KEY":"<api_key>"}
request = requests.get(url, params=params, verify=False)
metadata = request.json()
df = pd.DataFrame(metadata)`,
    getMetaDataR: `# Last updated on 11/06/2023 using R 4.1.3.
                    
# Import libraries
library(httr)
library(jsonlite)

# Get metadata
url <- "https://apiprod.dol.gov/v4/get/<agency>/<api_url>/json/metadata"
params <- list("X-API-KEY"="<api_key>")
request <- GET(url,query=params)
metadata <- as.data.frame(fromJSON(content(request,"text")))`,
    getDatasetPython: `# Last updated on 11/06/2023 using Python 3.9.

# Import libraries
import requests, pandas as pd

# Get datasets
url = "https://apiprod.dol.gov/v4/datasets?page=1"
request = requests.get(url, verify=False)
datasets = request.json()['datasets']
df = pd.DataFrame(datasets)`,
    getDatasetR: `# Last updated on 11/06/2023 using R 4.1.3. 

# Import libraries
library(httr)
library(jsonlite)

# Get datasets
url <- "https://apiprod.dol.gov/v4/datasets?page=1"
request <- GET(url)
datasets <- as.data.frame(fromJSON(content(request,"text"))[1])`,
  };
  return (
    <>
      <div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              onClick={() => configCodeBlock("Python", snippetContentPython)}
              data-toggle="tab"
              href="#tab1"
              role="tab"
            >
              Python
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={() => configCodeBlock("R", snippetContentR)}
              data-toggle="tab"
              href="#tab2"
              role="tab"
            >
              R
            </a>
          </li>
        </ul>
      </div>
      <div>
        <CodeBlock
          text={check[snippetContent]}
          language={language}
          showLineNumbers={true}
          theme="atom-one-dark"
          codeBlock={{ lineNumbers: false, wrapLines: true }}
        />
      </div>
    </>
  );
}

export default CodeBlocks;
