/* eslint-disable react/jsx-no-bind */
import { React, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import PreContentComponent from '../data/PreContentComponent';
import HeroBanner from './HeroBanner';
import ApiExampleText from './ApiExampleText';
import '../../css/api_example.css';

function ApiExamples() {
  const [text, setText] = useState('Overview');
  let [navStat, setnavStat] = useState({ Overview: 'active first' });
  // const [snippetDefaultContent, snippetDefaultContent] = useState('Python');
  const [snippetContent, setsnippetContent] = useState('');
  const [snippetTypeJSON, setSnippetTypeJSON] = useState('Python');
  const [language, setLangauge] = useState('Python');

  function setNavLink(id) {
    document.getElementById(id).click();
  }
  function menuClick(text, defaultContent) {
    navStat = {};
    if (!$(`#${text}`).hasClass('first')) {
      $('.first').css({
        border: '1px solid black',
        'background-color': '#FFF',
        color: 'black',
      });
    } else {
      $('.first').css({
        borderLeft: '8px solid #045EA3',
        'background-color': '#F1F1F1',
        color: 'black',
      });
    }
    setsnippetContent(defaultContent);
    setText(text);
  }

  // $('.nav-tabs li a').click(function (e) {
  //   e.preventDefault();
  //   e.stopImmediatePropagation();
  //   $(this).tab('show');
  // });

  function configCodeBlock(language, type) {
    setsnippetContent(type);
    setLangauge(language);
  }

  function configCodeBlockJSON(language, type) {
    setSnippetTypeJSON(type);
    setLangauge('JSON');
  }

  return (
    <div className="pre-content">
      <PreContentComponent spanText="API Examples" />
      <HeroBanner page="api" />
      <div className="container">
        <Row>
          <Col lg={3} id="meta_nav">
            <Nav
              defaultActiveKey="/home"
              id="meta_menu"
              className="flex-column"
            >
              <Nav.Link
                className={navStat.Overview}
                id="Overview"
                href="javascript:void(0)"
                onClick={() => menuClick('Overview')}
                eventKey="link-1"
              >
                Overview
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('Dataset', 'getDatasetPython')}
                eventKey="link-2"
                href="javascript:void(0)"
                className={navStat.Dataset}
                id="Overview"
              >
                Get Datasets
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('Metadata', 'getMetaDataPython')}
                eventKey="link-3"
                href="javascript:void(0)"
                className={navStat.Metadata}
                id="linkMetadata"
              >
                Get Metadata
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('Data', 'getDataPython')}
                eventKey="link-4"
                href="javascript:void(0)"
                className={navStat.Data}
                id="linkData"
              >
                Get Data
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('Toolkit')}
                eventKey="link-5"
                href="javascript:void(0)"
                className={navStat.Toolkit}
              >
                Excel Toolkit
              </Nav.Link>
            </Nav>
          </Col>
          <Col lg={7} id="api_text_section">
            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
            <h2 id="meta_heading" />
            <ApiExampleText
              type={text}
              snippetContent={snippetContent}
              snippetTypeJSON={snippetTypeJSON}
              jsonType=""
              language={language}
              configCodeBlock={configCodeBlock}
              configCodeBlockJSON={configCodeBlockJSON}
              setNavLink={setNavLink}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ApiExamples;
