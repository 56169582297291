import React, { Component } from 'react';

function SkipComponent(props) {
  function skip() {
    document.getElementById('skipLink').focus();
  }
  return (
    <a href="javascript:void" onClick={skip} className="visually-hidden focusable">
      Skip to main content
    </a>
  );
}
export default SkipComponent;
