/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React from 'react';
import 'swagger-ui-react/swagger-ui.css';
import jwt_decode from 'jwt-decode';
import {
  Switch, Route, Redirect, withRouter,
} from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { AuthProvider } from 'oidc-react';
import Spinner from 'react-bootstrap/Spinner';
import FooterComponent from './components/FooterComponent/FooterComponent';
import NavBarComponent from './components/NavBarComponent/NavBarComponent';
import ApiKeys from './components/apiKeys';
import PageNotFound from './components/page_not_found';
import HomePage from './components/StaticComponents/HomePage';
import Dataset from './components/datasets';
import Detail from './components/detail';
import About from './components/StaticComponents/About';
import UserGuide from './components/StaticComponents/UserGuide';
import GettingStarted from './components/StaticComponents/GettingStarted';
import Tutorials from './components/StaticComponents/Tutorials';
import ApiExamples from './components/StaticComponents/ApiExamples';
import VisualizationGallery from './components/StaticComponents/VisualizationGallery';
import AskaQuestion from './components/askAQuestion';
import FAQs from './components/FAQs';
import Questionnaire from './components/QuestionnaireComponent/QuestionnaireComponent';
import Registration from './components/RegistrationComponent/RegistrationComponent';
import emailVerify from './components/emailVerify';
import SkipComponent from './components/SkipComponent';
import MetaData from './components/StaticComponents/MetaData';
import './css/global.css';

class App extends React.Component {
  mutationObserver = new MutationObserver((mutations) => {
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
    mutations.forEach((mutation) => {
      document.querySelectorAll('.btn.try-out__btn.cancel').forEach((div) => {
        div.style.visibility = 'hidden';
      });
      document
        .querySelectorAll('.btn.try-out__btn:not(.cancel)')
        .forEach((div) => {
          div.click();
        });
    });
  });

  constructor() {
    super();
    this.state = {
      currentUser: null,
      newAccount: false,
      loading: true,
      regLoading: false,
      tokens: [],
      datasets: null,
      metaSets: null,
      agencies: null,
      tags: null,
      categories: null,
      prevPath: '',
      dataSetLoading: true,
      tokensLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      lastPressedKey: null,
    };
  }

  // FIXME : should we be lazy loading all data like tags , categoris , agencies .. redux is a solution?

  componentDidMount() {
    const isIE = /edge|msie\s|trident\//i.test(window.navigator.userAgent);
    if (isIE) {
      const script = document.createElement('script');
      script.src = 'https://cdnjs.cloudflare.com/ajax/libs/babel-core/5.6.15/browser-polyfill.min.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
    this.fetchDataSets('?page=1&sortby=name&order=asc');

    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleKeyDown);

    fetch(`${process.env.REACT_APP_DATA_PROXY_URL}/tags`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          tags: data.tags,
        });
      })
      .catch((error) => {
        this.setState({
          tags: [],
        });
        console.log('error', error);
      });

    fetch(`${process.env.REACT_APP_DATA_PROXY_URL}/agencies`)
      .then((res) => res.json())
      .then((data) => this.setState({
        agencies: data.agencies,
      }))
      .catch((error) => {
        console.log('error', error);
        this.setState({
          agencies: [],
        });
      });

    fetch(`${process.env.REACT_APP_DATA_PROXY_URL}/categories`)
      .then((res) => res.json())
      .then((data) => this.setState({
        categories: data.categories,
      }))
      .catch((error) => {
        console.log('error', error);
        this.setState({
          categories: [],
        });
      });

    const token = localStorage.getItem('token');
    if (token) {
      const tokenData = jwt_decode(token);
      if (tokenData.exp > Date.now() / 1000) {
        this.setCurrentUser({
          email: tokenData.email,
        });
        this.fetchAPIKeys();
        this.onChangeLoading(false);
      } else {
        this.logout();
      }
    } else {
      this.onChangeLoading(false);
    }

    const access_token = localStorage.getItem('token');
    if (access_token) {
      const tokenData = jwt_decode(access_token);
      if (tokenData.exp <= Date.now() / 1000) {
        this.logout();
      }
    }
  }

  fetchDataSets = (paramUrl) => {
    fetch(`${process.env.REACT_APP_DATA_PROXY_URL}/datasets/${paramUrl}`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          datasets: data.datasets,
          // Missing metadata from datasets endpoint
          metaSets: {
            datasetscurrent_page: data.meta.current_page,
            next_page: data.meta.next_page,
            prev_page: data.meta.prev_page,
            total_count: data.meta.total_count,
            total_pages: data.meta.total_pages,
          },

          dataSetLoading: false,
        });
      })
      .catch((error) => {
        console.log('error', error);

        this.setState({
          datasets: [],
          metaSets: {
            datasetscurrent_page: 1,
            next_page: null,
            prev_page: null,
            total_count: 1,
            total_pages: 1,
          },
          dataSetLoading: false,
        });
      });
  };

  setPrevPath = (pathString) => {
    this.setState({ prevPath: pathString });
  };

  onChangeLoading = (bool) => {
    this.setState({ loading: bool });
  };

  setCurrentUser = (obj) => {
    this.setState({
      currentUser: obj,
    });
  };

  setTokens = (tokens) => {
    this.setState({ tokens });
  };
  /* eslint-disable */
  RegistrationRedirect = (token) => {
    if (!token.enabled)
      window.location.href =
        process.env.REACT_APP_REDIRECT_URL + "questionnaire";
  };
  /* eslint-disable */

  fetchAPIKeys = (s) => {
    const access_token = localStorage.getItem("token");
    this.setState({ tokensLoading: true });
    if (access_token) {
      const tokenData = jwt_decode(access_token);
      if (tokenData.exp > Date.now() / 1000) {
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/apikey`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            const tokens = [];
            data.keys.map((singleToken) => tokens.push(singleToken));
            tokens.sort((a, b) => {
              const nameA = a.is_default;
              const nameB = b.is_default;
              if (nameA > nameB) return -1;
              if (nameA < nameB) return 1;
              return 0;
            });
            this.setState({
              tokens: [...tokens],
              tokensLoading: false,
            });
            this.setApiKeyInSwagger(s);
            if (this.state.prevPath) {
              this.props.history.push(this.state.prevPath);
            }
          })
          .catch((error) =>
            this.setState({
              tokens: error,
              tokensLoading: false,
            })
          );
      }
    }
  };

  setApiKeyInSwagger = (swaggerComponent) => {
    if (swaggerComponent && this.state.tokens.length > 0) {
      swaggerComponent.preauthorizeApiKey("api_key", this.state.tokens[0].key);
      swaggerComponent.authActions.logout = () => {
        this.logout();
      };
    }
  };

  logout = () => {
    this.setCurrentUser(null);
    localStorage.clear();
    this.setTokens([]);
  };

  handleKeyDown = (event) => {
    this.setState({ lastPressedKey: event });
  };
  clearKeyPress = () => {
    this.setState({ lastPressedKey: null });
  };
  render() {
    function sendKeyPress() {
      const keys = {
        key: this.state.lastPressedKey.key,
        shiftKey: this.state.lastPressedKey.shiftKey,
        type: this.state.lastPressedKey.type,
        className: this.state.lastPressedKey.target.className,
        innerText: this.state.lastPressedKey.target.innerText,
      };
      return keys;
    }
    const oidcConfig = {
      onSignIn: async (user) => {
        this.setState({ regLoading: true });
        this.setCurrentUser({
          username: "",
          first_name: "",
          last_name: "",
          email: user.profile.email,
        });
        // Clear redirect code from URL
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/user`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        })
          .catch((error) => {
            this.logout();
          })
          .then((response) => response.json())
          .then((data) => {
            localStorage.setItem("token", data.jwt);
            this.fetchAPIKeys();
            const tokenContents = jwt_decode(data.jwt);
            console.log(tokenContents + " before reg function");
            this.setState({ regLoading: false });
            this.RegistrationRedirect(tokenContents);
          });
      },
      authority: process.env.REACT_APP_OPENID_AUTHORITY,
      clientId: process.env.REACT_APP_OPENID_CLIENTID,
      redirectUri: process.env.REACT_APP_REDIRECT_URL,
      responseType: "code",
      scope: "openid email",
      extraQueryParams: {
        // Using the Code Authorization flow nonce is an optional argument and this library doesn't verify it.
        // Login.Gov does require a nonce as part of the request so I have included a valid nonce value but it will not be verified.
        // See section 3.1.2.1.Authentication Request of the OpenID Specs https://openid.net/specs/openid-connect-core-1_0.html#NonceNotes
        nonce: "pUgWsHmxHcEiKwXBzZNEPKO21xDdJl",
        acr_values: "http://idmanagement.gov/ns/assurance/ial/1",
      },
      autoSignIn: false,
    };

    this.mutationObserver.observe(document.documentElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });
    return !this.state.loading ? (
      <>
        <SkipComponent />
        <Helmet
          titleTemplate="%s - Open Data Portal | United States Department of Labor"
          defaultTitle="Open Data Portal| United States Department of Labor"
        />
        <AuthProvider {...oidcConfig}>
          <NavBarComponent
            currentUser={this.state.currentUser}
            loading={this.state.regLoading}
            logout={this.logout}
            sendKeyPress={sendKeyPress.bind(this)}
            clearKeyPress={this.clearKeyPress}
          />
          {!this.state.regLoading ? (
            <Switch>
              {this.state.datasets ? (
                <Route
                  path="/datasets/:id"
                  render={() => (
                    <Detail
                      tokens={this.state.tokens}
                      currentUser={this.state.currentUser}
                      setApiKeyInSwagger={this.setApiKeyInSwagger}
                      setPrevPath={this.setPrevPath}
                    />
                  )}
                />
              ) : null}

              <Route
                path="/datasets"
                render={() => (
                  <Dataset
                    datasets={this.state.datasets}
                    fetchDataSets={this.fetchDataSets}
                    currentUser={this.state.currentUser}
                    metaSets={this.state.metaSets}
                    dataSetLoading={this.state.dataSetLoading}
                    agencies={this.state.agencies}
                    categories={this.state.categories}
                    tags={this.state.tags}
                  />
                )}
              />
              <Route
                path="/api-keys"
                render={() => (
                  <ApiKeys
                    setTokens={this.setTokens}
                    currentUser={this.state.currentUser}
                    tokens={this.state.tokens}
                    setPrevPath={this.setPrevPath}
                    tokensLoading={this.state.tokensLoading}
                    fetchAPIKeys={this.fetchAPIKeys}
                  />
                )}
              />
              <Route exact path="/faq" component={FAQs} />
              <Route
                exact
                path="/contact-us"
                render={() => (
                  <AskaQuestion currentUser={this.state.currentUser} />
                )}
              />
              <Route exact path="/about" component={About} />
              <Route exact path="/user-guide" component={UserGuide} />
              <Route exact path="/api-examples" component={ApiExamples} />
              <Route exact path="/api-examples/:page" component={ApiExamples} />
              <Route exact path="/questionnaire">
                <Questionnaire currentUser={this.state.currentUser} />
              </Route>
              <Route exact path="/registration">
                {this.state.currentUser ? (
                  <Redirect to="/api-keys" />
                ) : (
                  <Registration currentUser={this.state.currentUser} />
                )}
              </Route>
              <Route
                exact
                path="/visualization-gallery"
                component={VisualizationGallery}
              />
              <Route
                exact
                path="/visualization-gallery/:page"
                component={VisualizationGallery}
              />
              <Route exact path="/metadata" component={MetaData} />
              <Route exact path="/email-verify" component={emailVerify} />
              <Route exact path="/" component={HomePage} />
              <Route exact path="/getting-started" component={GettingStarted} />
              <Route exact path="/video-tutorials" component={Tutorials} />
              <Route component={PageNotFound} />
            </Switch>
          ) : (
            <Col className="text-center loginGovLoader ">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          )}
          <FooterComponent />
        </AuthProvider>
      </>
    ) : null;
  }
}

export default withRouter(App);
