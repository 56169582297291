import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';
import FormikErrorFocus from 'formik-error-focus';
import {
  Button, Form, Alert, Col, Row,
} from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import ContentComponent from '../data/ContentComponent';
import ModalComponent from '../ModalComponent/ModalComponent';
import '../../css/questionaire.css';
import '../../css/global.css';

class RegistrationQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: false,
      regLoading: true,
      questions: [],
      otherAnswer: {},
      otherError: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const initialValues = {};

    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/registration_questions`)
      .then((res) => res.json())
      .then((data) => {
        data.questions.forEach((ques, index) => {
          const name = `question_${index + 1}`;
          initialValues[name] = '';
        });
        this.setState({
          questions: data.questions,
        });
        this.setState({
          regLoading: false,
        });
      });
  }

  handleOtherValue = (name, value) => {
    const otherAns = this.state.otherAnswer;
    const { otherError } = this.state;
    otherAns[name] = value;
    delete otherError[name];
    this.setState({
      otherAnswer: otherAns,
      otherError,
    });
  };

  validateOtherOnBlur = (name) => {
    const { otherError } = this.state;
    if (!this.state.otherAnswer[name]) {
      otherError[name] = 'If other is selected, this field is required.';
      this.setState({
        otherError,
      });
    }
  };

  render() {
    const term = '\u00A0Terms of Service';
    const noShowOption = 'I would like to receive email about updates and changes to the API service.';
    const noShowTerms = 'I agree to the Terms of Service.';
    const validationSchema = Yup.object().shape({
      question_1: Yup.string().required('Field is required'),
      question_2: Yup.string().required('Field is required'),
      question_3: Yup.string().required('Field is required'),
      question_4: Yup.array().required('Field is required'),
      question_5: Yup.array().required('Field is required'),
      question_6: Yup.string(),
      question_7: Yup.string(),
      terms_of_service: Yup.mixed().test({
        name: 'termsOfServiceTest',
        message: 'You must accept the terms and services.',
        test: (value) => {
          if (!value) return false;
          return true;
        },
      }),
      subscribed_to_emails: Yup.bool(),
    });

    return (
      <>
        <Helmet>
          <title>Department of Labor API Registration</title>
          <meta
            name="description"
            content="This is a different description for this route."
          />
        </Helmet>
        <ContentComponent
          mainHeader="Department of Labor API Registration"
          className="basic-title-banner-title-container"
          spanText="Department of Labor API Registration"
          centered
          displayName="registration"
        />
        <Row className="justify-content-center">
          {this.state.loading && (
            <Col className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          )}
          {this.state.error ? (
            <Col lg={6} className="align-self-center">
              <Alert variant="danger">
                {HTMLReactParser(this.state.error)}
              </Alert>
            </Col>
          ) : null}
        </Row>
        <Row className="justify-content-center">
          <Formik
            initialValues={{
              question_1: '',
              question_2: '',
              question_3: '',
              question_4: '',
              question_5: '',
              question_6: '',
              question_7: '',
              subscribed_to_emails: true,
              terms_of_service: '',
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              const formdata = new FormData();

              let isSubmit = true;
              Object.keys(values).forEach((key, index) => {
                let val;
                if (Array.isArray(values[key])) {
                  val = Object.assign([], values[key]);
                  if (val.includes('Other')) {
                    const otherAns = this.state.otherAnswer[key];
                    if (otherAns) {
                      val[val.indexOf('Other')] = otherAns;
                    } else {
                      isSubmit = false;
                      this.setState({
                        otherError: {
                          ...this.state.otherError,
                          [key]: 'Please specify the value for other',
                        },
                      });
                    }
                  }
                } else {
                  val = values[key];
                }
                const ansKey = key.includes('question')
                  ? `answer_${index + 1}`
                  : key;
                if (
                  this.state.questions[index]?.question
                  === 'I agree to the Terms of Service.'
                ) {
                  formdata.append(
                    this.state.questions[index].question,
                    values.terms_of_service,
                  );
                } else if (
                  typeof this.state.questions[index]?.question !== 'undefined'
                ) {
                  if (
                    this.state.questions[index]?.question
                    === 'Rate your level of experience with using APIs.'
                  ) {
                    formdata.append(
                      this.state.questions[index]?.question,
                      '1 - Beginner',
                    );
                  } else {
                    formdata.append(this.state.questions[index]?.question, val);
                  }
                }
              });

              const json = {};
              Array.from(formdata.entries()).forEach(([key, value]) => {
                json[key] = value;
              });

              const newData = JSON.stringify(json);

              const auth_token = localStorage.getItem('token');

              if (!isSubmit) {
                return;
              }

              const requestOptions = {
                method: 'POST',
                body: newData,
                headers: {
                  Authorization: `Bearer ${auth_token}`,
                },
              };
              this.setState({
                loading: true,
              });
              window.scrollTo(0, 0);
              fetch(
                `${process.env.REACT_APP_SERVER_BASE_URL}/registration_questions`,
                requestOptions,
              )
                .then((res) => res.json())
                .then((response) => {
                  if (response.message) {
                    this.props.history.push({
                      pathname: '/email-verify',
                      state: { message: response.message },
                    });
                    resetForm();
                  } else if (response.error) {
                    this.setState({
                      error: response.error,
                      loading: false,
                    });
                    window.scrollTo(0, 0);
                  } else if (response.errors) {
                    const arrangedFormatResponse = Object.entries(
                      response.errors,
                    ).map(([k, v]) => `${k}: ${v[v.length - 1]}`);

                    this.setState({
                      error: arrangedFormatResponse.join('<br />'),
                      loading: false,
                    });
                    setSubmitting(false);
                    window.scrollTo(0, 0);
                  }
                  setSubmitting(false);
                })
                .catch((error) => {
                  console.log('error from catch', error);
                  this.setState({
                    error,
                    loading: false,
                  });
                  setSubmitting(false);
                  window.scrollTo(0, 0);
                });
            }}
            validationSchema={validationSchema}
          >
            {(formProps) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldError,
              } = formProps;
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Row className="justify-content-center">
                    <Form.Group id="questions" as={Col} md={8} sm={10}>
                      {!this.state.regLoading ? (
                        <>
                          <Form.Text className="form-row formText">
                            <b>Notice</b><br />
                            <div style={{ fontSize: '11px' }}>
                              According to the Paperwork Reduction Act of 1995, no
                            persons are required to respond to a collection of
                            information unless such collection displays a valid
                            OMB control number. Public reporting burden for this
                            collection of information is estimated to average 9
                            minutes per response, including time for reviewing
                            instructions, searching existing data sources,
                            gathering and maintaining the data needed, and
                            completing and reviewing the collection of
                            information. The obligation to respond to this
                            collection is voluntary. Send comments regarding the
                            burden estimate or any other aspect of this
                            collection of information, including suggestions for
                            reducing this burden, to the U.S. Department of
                            Labor, Office of the Chief Information Officer,
                            Attention: Departmental Clearance Officer, 200
                            Constitution Avenue, N.W., Room N-1301, Washington,
                            DC 20210 or email DOL_PRA_PUBLIC@dol.gov and
                            reference the OMB Control Number. Note: Please do
                            not return the completed web application to this
                            address.
                            </div>
                          </Form.Text>
                          <Form.Text className="form-row formText" style={{ display: 'none' }}>
                            To access the Department of Labor's (DOL) open data
                            service, you need register for an API key. After you
                            register below, you will receive an email with your
                            API key and instructions to get started.
                          </Form.Text>
                          <h2 className="headerTextColor">
                          API User Questionnaire
                          </h2>
                          <Form.Text className="form-row formText">
                          To access our APIs, fill out the questionnaire and agree to our Terms of the Service below. DOL will use the information provided to prioritize the creation of tools and resources to
best meet customer needs. Once completed, your API key will be generated. Your API key will look like a mixture of numbers, letters, and special characters and it is what grants you
access to our data and metadata. Please note that there may be a slight delay while the system incorporates your credentials, and you may need to wait for a few minutes and/or
refresh your screen before you see an API key in your account. Thank you for registering for our Open Data API.
                          </Form.Text>
                          <Form.Text className="form-row formText requiredText">
                          All fields are required.
                          </Form.Text>
                          {this.state.questions.map((ques, index) => {
                            const name = `question_${ques.id}`;
                            const { question } = ques;
                            const {
                              answer_options: { choices, multiple = '' } = {},
                            } = ques;
                            const select_questions = [1, 2, 3];
                            return (
                              <>
                                {question !== noShowTerms
                                  && question !== noShowOption && (
                                    <Form.Label className="labelHeight">
                                      {`${index + 1}. ${question}`}{' '}
                                    </Form.Label>
                                )}
                                {this.regLoading}
                                {touched[name]
                                  && errors[name]
                                  && question !== noShowTerms
                                  && question !== noShowOption && (
                                    <span className="errors_span">
                                      {errors[name]}
                                    </span>
                                )}
                                {choices
                                && select_questions.includes(ques.id) ? (
                                  <Form.Control
                                    as="select"
                                    name={name}
                                    className={name}
                                    onChange={handleChange}
                                    isInvalid={touched[name] && errors[name]}
                                  >
                                    <option value="Select">-Select-</option>
                                    {choices
                                      && choices.map((choice, ind) => {
                                        const key = Object.keys(choice)[0];
                                        return (
                                          <option value={key}>{key}</option>
                                        );
                                      })}
                                  </Form.Control>
                                  ) : (
                                    ''
                                  )}
                                {choices && !select_questions.includes(ques.id)
                                  ? choices.map((choice, ind) => {
                                    const key = Object.keys(choice)[0];
                                    return (
                                      <Form.Check
                                        type="checkbox"
                                        name={name}
                                        className={name}
                                        label={choice[key]}
                                        id={`${name + choice[key]}`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={
                                            touched[name] && errors[name]
                                          }
                                        value={key}
                                        key={key}
                                      />
                                    );
                                  })
                                  : ''}
                                {Array.isArray(values[name])
                                  && values[name].includes('Other') && (
                                    <>
                                      <Form.Control
                                        type="text"
                                        name={name}
                                        className={name}
                                        placeholder="If other, please specify"
                                        value={this.state.otherAnswer[name]}
                                        onBlur={(event) => this.validateOtherOnBlur(name)}
                                        onChange={({ target: { value } }) => this.handleOtherValue(name, value)}
                                        isInvalid={
                                          touched[name]
                                          && this.state.otherError[name]
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {this.state.otherError[name]}
                                      </Form.Control.Feedback>
                                    </>
                                )}
                                {!choices
                                  && question !== noShowTerms
                                  && question !== noShowOption && (
                                    <>
                                      <Form.Control
                                        type="text"
                                        name={name}
                                        className={name}
                                        onChange={handleChange}
                                        value={values.question_6}
                                        onBlur={handleBlur}
                                        isInvalid={
                                          touched[name] && errors[name]
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                      </Form.Control.Feedback>
                                    </>
                                )}
                              </>
                            );
                          })}
                          <Form.Row className="justify-content-left">
                            <Form.Group as={Col} md={8} sm={10}>
                              <div className="half_rem_margin_div">
                                <a
                                  href="#a"
                                  className="text-primary"
                                  onClick={() => this.setState({ modalShow: true })}
                                >
                                  {term}
                                </a>
                                <ModalComponent
                                  show={this.state.modalShow}
                                  onHide={() => this.setState({ modalShow: false })}
                                  dialogClassName
                                />
                              </div>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row className="justify-content-left">
                            <Form.Group
                              className="termsBlock"
                              as={Col}
                              md={8}
                              sm={10}
                            >
                              <Form.Check
                                required
                                name="terms_of_service"
                                label="I agree to the Terms of Service"
                                onChange={({ target }) => {
                                  setFieldValue(
                                    'terms_of_service',
                                    target.checked,
                                  );
                                  if (
                                    touched.terms_of_service
                                    && !target.checked
                                  ) {
                                    setFieldError(
                                      'terms_of_service',
                                      'You must accept the terms and services.',
                                    );
                                  }
                                }}
                                isInvalid={
                                  touched.terms_of_service
                                  && errors.terms_of_service
                                }
                                id="checkValidTerms"
                                feedback={errors.terms_of_service}
                              />
                              {touched.terms_of_service
                                && errors.terms_of_service && (
                                  <div className="errors_span">
                                    {errors.terms_of_service}
                                  </div>
                              )}
                            </Form.Group>
                          </Form.Row>
                          <Form.Row className="justify-content-left">
                            <Form.Group as={Col} md={8} sm={10}>
                              <Form.Check
                                name="subscribed_to_emails"
                                label="I would like to receive communication about updates and scheduled changes to the API service"
                                onChange={({ target }) => setFieldValue(
                                  'subscribed_to_emails',
                                  target.checked,
                                )}
                                isInvalid={!!errors.subscribed_to_emails}
                                feedback={errors.subscribed_to_emails}
                                id="checkValidUpdates"
                                checked
                              />
                            </Form.Group>
                          </Form.Row>
                          <Col>
                            <Button type="submit">Register for API Key</Button>
                          </Col>
                          <FormikErrorFocus
                            offset={-75}
                            align="top"
                            focusDelay={200}
                            ease="linear"
                            duration={1000}
                          />
                        </>
                      ) : (
                        <Col className="text-center loginGovLoader ">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </Col>
                      )}
                    </Form.Group>
                  </Form.Row>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </>
    );
  }
}

export default withRouter(RegistrationQuestion);
