/* eslint-disable import/no-extraneous-dependencies */
import { React, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import $ from 'jquery';
import PreContentComponent from '../data/PreContentComponent';
import HeroBanner from './HeroBanner';
import TutorialText from './TutorialText';
import '../../css/tutorial.css';

function Tutorials() {
  const [text, setText] = useState('gettingStarted');
  function menuClick(text) {
    $('#transcript_toggle_button').text('Show transcript');
    $('#transcript_container').hide();
    if (!$(`#${text}`).hasClass('first')) {
      $('.first').css({
        border: '1px solid black',
        'background-color': '#FFF',
      });
    } else {
      $('.first').css({
        borderLeft: '8px solid #045EA3',
        'background-color': '#F1F1F1',
      });
    }
    setText(text);
  }
  return (
    <div className="pre-content">
      <PreContentComponent spanText="About" />
      <HeroBanner page="tutorial" />
      <div className="container">
        <Row>
          <Col lg={3} id="meta_nav">
            <Nav
              defaultActiveKey="/home"
              id="meta_menu"
              className="flex-column"
            >
              <Nav.Link
                className="active first"
                id="gettingStarted"
                onClick={() => menuClick('gettingStarted')}
                eventKey="link-1"
                href="javascript:void(0)"
              >
                Getting Started with the API
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('findData')}
                id="findData"
                eventKey="link-2"
                href="javascript:void(0)"
              >
                Finding Data in the Data Catalog
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('navigatingDataset')}
                eventKey="link-3"
                id="navigatingDataset"
                href="javascript:void(0)"
              >
                Navigating the Dataset Pages
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('queryBuilder')}
                eventKey="link-4"
                id="queryBuilder"
                href="javascript:void(0)"
              >
                API Query Builder Overview
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('apiExamples')}
                eventKey="link-5"
                id="apiExamples"
                href="javascript:void(0)"
              >
                API Examples
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('FLSA')}
                id="FLSA"
                eventKey="link-6"
                href="javascript:void(0)"
              >
                API Example: FLSA Backwages by Industry
              </Nav.Link>
            </Nav>
          </Col>
          <Col lg={7} id="meta_text_section">
            <TutorialText type={text} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Tutorials;
