/* eslint-disable */
import { React, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import PreContentComponent from "../data/PreContentComponent";
import HeroBanner from "./HeroBanner";
import MetaText from "./MetaText";
import "../../css/meta.css";

function MetaData() {
  // Sets default text
  const [text, setText] = useState("Overview");
  function menuClick(text) {
    if (!$(`#${text}`).hasClass("first")) {
      $(".first").css({
        border: "1px solid black",
        "background-color": "#FFF",
      });
    } else {
      $(".first").css({
        borderLeft: "8px solid #045EA3",
        "background-color": "#F1F1F1",
      });
    }
    setText(text);
  }
  $(() => {
    $("#show_all_button").click(() => {
      $(".collapse").collapse("show");
    });

    $("#hide_all_button").click(() => {
      $(".collapse").collapse("hide");
    });
  });
  return (
    <>
      <div className="pre-content">
        <PreContentComponent spanText="Metadata" />
        <HeroBanner page="meta" />
        <div className="container">
          <Row>
            <Col lg={3} id="meta_nav">
              <Nav
                defaultActiveKey="/home"
                id="meta_menu"
                className="flex-column"
              >
                <Nav.Link
                  className="active first"
                  onClick={() => menuClick("Overview")}
                  eventKey="link-1"
                  id="Overview"
                  href="javascript:void(0)"
                >
                  Overview
                </Nav.Link>
                <Nav.Link
                  onClick={() => menuClick("Metadata")}
                  eventKey="link-2"
                  href="javascript:void(0)"
                >
                  Substantive Values
                </Nav.Link>
                <Nav.Link
                  onClick={() => menuClick("missingValues")}
                  eventKey="link-3"
                  href="javascript:void(0)"
                >
                  Missing Values
                </Nav.Link>
                <Nav.Link
                  onClick={() => menuClick("valuelessEntries")}
                  eventKey="link-4"
                  href="javascript:void(0)"
                >
                  Valueless Entries
                </Nav.Link>
                <Nav.Link
                  onClick={() => menuClick("descriptiveStatistics")}
                  eventKey="link-5"
                  href="javascript:void(0)"
                >
                  Descriptive Statistics
                </Nav.Link>
              </Nav>
            </Col>
            <Col lg={7} id="meta_text_section">
              <MetaText type={text} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default MetaData;
